import * as React from "react";
import { Box, Image, Flex, Badge, Text, Container, Button, Link } from "@chakra-ui/react";
import { MdStar } from "react-icons/md";

import WContainer from './WContainer'

import logo from '../images/logo.png';

export default function PromoBar(props) {
    return (
        <Box d="flex" justifyContent="center" alignItems="center" bgGradient="linear(to-r, #65b8ff, #9d7efd, #f87b90)" >
            {/*<Text color="white">✨ Reserva nuestro libro de curiosidades de Disneyland París ✨</Text>
            <Box d="flex" alignItems="center">
                <Button 
                    background="rgba(0,0,0,0.2)" 
                    color="white" 
                    size="xs" 
                    marginLeft="20px" 
                    _hover={{ bg: "rgba(0,0,0,0.4)" }}
                    _active={{
                        bg: "rgba(0,0,0,0.5)",
                        transform: "scale(0.98)"
                    }}
                >
                    Reservar ahora
                </Button>
            </Box>*/}
            <Box p={4} display={{ md: "flex" }}>
                <Box d="flex" justifyContent="center" alignItems="center" flexShrink={0}>
                    <Text color="white" textAlign="center">{props.text}</Text>
                </Box>
                <Box d="flex" mt={{ base: 4, md: 0 }} justifyContent="center" alignItems="center" ml={{ md: 6 }}>
                    <Button 
                        colorScheme="whiteAlpha"
                        background="rgba(0,0,0,0.2)" 
                        color="white" 
                        size="sm" 
                        style={{webkitTapHighlightColor: "transparent"}}
                        _hover={{bg: "rgba(0,0,0,0.4)"}}
                        _active={{
                            bg: "rgba(0,0,0,0.5)",
                            transform: "scale(0.95)"
                        }}
                        _focus={{
                            boxShadow:
                              "0 0 1px 2px rgba(255, 255, 255, .25), 0 1px 1px rgba(0, 0, 0, .15)",
                        }}
                    >
                        {props.buttonText}
                    </Button>
                </Box>
            </Box>
        </Box>
  );
}
