import * as React from "react";
import { Box, Image, Flex, Badge, Text, Container, Spacer, Button, IconButton, Wrap, WrapItem, Center } from "@chakra-ui/react";

import cat1 from '../images/foto_cat.jpg';
import CategorieItem from './CategorieItem'

export default function NavBar(props) {
    return (
        <Box maxW="100%" overflow="hidden" pt={{base: 7, md: 10}}>
            <Wrap spacing={{base: 5, md: 8}} justify="center">
              <WrapItem>
                <CategorieItem
                    titulo={"Hoteles 🏨"}
                    bgGradient={"linear(to-r, #2187a1, #4070b8, #805ad5)"}
                />
              </WrapItem>
              <WrapItem>
                <CategorieItem
                    titulo={"Parques 🏰"}
                    bgGradient={"linear(to-r, #018483, #2596be)"}
                />
              </WrapItem>
              <WrapItem>
                <CategorieItem
                    titulo={"Restaurantes 🍽"}
                    bgGradient={"linear(to-r, #f29753, #fccc4d)"}
                />
              </WrapItem>
              <WrapItem>
                <CategorieItem
                    titulo={"Consejos 💬"}
                    //bgGradient={"linear(to-r, #A30C4B, #DB0B0F)"}
                    bgGradient={"linear(to-r, #e37b5b, #ed5c7e)"}
                />
              </WrapItem>
              <WrapItem>
                <CategorieItem
                    titulo={"Curiosidades 💡"}
                    bgGradient={"linear(to-r, #814aa1,#C84686)"}
                />
              </WrapItem>
            </Wrap>
        </Box>
  );
}

