import * as React from "react";
import { Box, Image, Flex, Badge, Text, Container, Spacer, Button, IconButton, Wrap, WrapItem, Center } from "@chakra-ui/react";

import cat1 from '../images/foto_cat.jpg';

export default function NavBar(props) {
    return (
        <Button style={{webkitTapHighlightColor: "transparent"}} w={{base: "43vw", md: "180px"}} h="80px" bg="red.200" borderRadius="13" overflow="hidden"  boxShadow="md" mb="1.5" mt="-1.5" _active={{transform: "scale(0.95)"}} _hover={{transform: {md: "scale(1.05)"}}}>
            <Box d="flex" w={{base: "43vw", md: "180px"}} h="80px" style={{position: 'absolute'}} borderRadius="13" bgGradient={props.bgGradient} alignItems="center" justifyContent="center">
            </Box>
            <Image
                w={{base: "43vw", md: "180px"}} 
                h="80px" 
                style={{position: 'absolute', opacity: '0.1'}}
                borderRadius="13"
                src={cat1}
                objectFit="cover"
            />
            <Box d="flex" w={{base: "43vw", md: "180px"}} h="80px" style={{position: 'absolute'}} borderRadius="13" alignItems="center" justifyContent="center">
                <Box align="center">
                    <Text color="white" fontSize="xs" fontWeight="light">Disneyland París</Text>
                    <Text color="white" fontWeight="bold" fontSize="xl">{props.titulo}</Text>
                </Box>
            </Box>
        </Button>          
  );
}

