import * as React from "react";
import { Box, Image, Flex, Badge, Text, Container, Spacer, Button, IconButton, Link } from "@chakra-ui/react";

import foto1 from '../../images/foto_cat.jpg';

export default function PostV1(props) {
    return (
        <Box mr={props.right ? "60px" : "0px"}>
            <Button style={{webkitTapHighlightColor: "transparent"}} w={{base: "320px", "3lg": "320px"}} h={{base: "180px", "3lg": "180px"}} borderRadius="13" overflow="hidden"  boxShadow="lg" _active={{transform: "scale(0.98)"}} _hover={{transform: {md: "scale(1.02)"}}}>
                <Box w={{base: "320px", "3lg": "320px"}} h={{base: "180px", "3lg": "180px"}} borderRadius="13">
                    <Image
                        w={{base: "320px", md: "320px"}} 
                        h={{base: "180px", md: "180px"}}  
                        borderRadius="13"
                        style={{position: 'absolute', left: 0}}
                        src={foto1}
                        objectFit="cover"
                    />
                </Box>
            </Button>
            <Box d="flex" mt="10px">
                <Link
                    fontWeight="bold"
                    textTransform="uppercase"
                    fontSize="sm"
                    letterSpacing="wide"
                    bgGradient="linear(to-r, #65b8ff, #9d7efd, #f87b90)"
                    bgClip="text"
                    href="#"
                    _hover={{transform: {md: "scale(1.035)"}}}
                >
                    {props.categoria1}
                </Link>
                <Text color={"#bbc1c9"}>&nbsp;/&nbsp;</Text>
                <Link
                    fontWeight="bold"
                    textTransform="uppercase"
                    fontSize="sm"
                    letterSpacing="wide"
                    bgGradient="linear(to-r, #65b8ff, #9d7efd, #f87b90)"
                    bgClip="text"
                    href="#"
                    _hover={{transform: {md: "scale(1.035)"}}}
                >
                    {props.categoria2}
                </Link>
            </Box>
            <Box w={{base: "320px", md: "320px"}}>
                <Link color="#143b5c" fontSize="lg" fontWeight="extrabold" mt="-3px">{props.titulo}</Link>
                <Text mt="3px" color="gray.500" noOfLines={3} ellipsizeMode='tail'>
                    {props.descripcion}
                </Text>
            </Box>
        </Box>
  );
}

