import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  Heading,
  Container,
  Flex,
  Center,
  Wrap,
  WrapItem,
  Image,
  Spacer,
  extendTheme
} from '@chakra-ui/react';

import { createBreakpoints } from "@chakra-ui/theme-tools"

import NavBar from './components/NavBar/NavBar'
import WContainer from './components/WContainer'
import PromoBar from './components/PromoBar';
import CategoriesBar from './components/CategoriesBar';
import Post from './components/HomeContent/Post';
import PiePagina from './components/PiePagina'
import ContentHomeV1 from './components/ContentHomeV1'

import foto1 from './images/foto_cat.jpg';
import foto2 from './images/foto_cat2.jpg';
import foto3 from './images/hotel-new-york2.jpg';

const breakpoints = createBreakpoints({
  sm: "30em",
  md: "48em",
  lg: "62em",
  "2lg": "1080px",
  "3lg": "1140px",
  xl: "80em",
  "2xl": "96em",
})

const theme = extendTheme({ breakpoints })

function App() {
  return (
    <ChakraProvider theme={theme} bg="transparent">
      <VStack>
        <Container maxW="100vw" paddingLeft="0px" paddingRight="0px">
          <NavBar/>
          <PromoBar
            text="✨ Reserva nuestro libro de curiosidades de Disneyland París ✨"
            buttonText="Reservar ahora"
          />
          <CategoriesBar/>
          {/*<Box d="flex" justifyContent="center" pt={{base: 50, md: 90}} pb={{base: 25, md: 55}}>
            <Box>
              <Box justifyContent="center" d="flex">
                <Text fontSize="3xl">✨&nbsp;</Text>
                <Text fontSize="3xl" fontWeight="extrabold" color="#38414a" bgGradient="linear(to-r, #65b8ff, #9d7efd, #f87b90)" bgClip="text">Lo más destacado</Text>
                <Text fontSize="3xl">&nbsp;✨</Text>
              </Box>
              <Text color="gray.500" align="center" pl="5" pr="5" mt="2">Echa un vistazo a los artículos más destacados de El Reino de la Magia</Text>
            </Box>
          </Box>*/}

          <Box maxW="100%" overflow="hidden" pt={{base: 7, md: 10}}>
            <Wrap spacing={{base: 0, md: 0}} justify="center">
              <WrapItem>
                <Box w={{base: "100vw", "3lg": "820px"}} bg="transparent">
                  <Box d="flex">
                    <Text fontSize="3xl">✨ &nbsp;</Text>
                    <Text fontSize="3xl" fontWeight="extrabold" color="#38414a" bgGradient="linear(to-r, #65b8ff, #9d7efd, #f87b90)" bgClip="text">Lo más destacado</Text>
                  </Box>
                  <Text color="gray.500">Descubre los artículos más destacados de El Reino de la Magia</Text>
                  <Box w={{base: "100vw", "3lg": "800px"}} mt="30px" bg="">
                    <Box d="flex">
                      <Post
                        right={true}
                        categoria1={"DISNEYLAND PARÍS"}
                        categoria2={"HOTELES"}
                        titulo={"Hotel New York: Lo mejor para los apasionados de Marvel"}
                        descripcion={"La reforma del hotel esta en marcha desde comienzos de 2019 y creará un entorno que evoca un lujoso y contemporáneo establecimiento de Manhattan. Su diseño interior será elegante y cosmopolita, al estilo de Tony Stark (Iron Man) y el cuartel general de Los Vengadores. Obras en ediciones limitadas adornarán las paredes de las habitaciones y suites."}
                      />
                      <Post
                        categoria1={"DISNEYLAND PARÍS"}
                        categoria2={"HOTELES"}
                        titulo={"Hotel New York: Lo mejor para los apasionados de Marvel"}
                        descripcion={"La reforma del hotel esta en marcha desde comienzos de 2019 y creará un entorno que evoca un lujoso y contemporáneo establecimiento de Manhattan. Su diseño interior será elegante y cosmopolita, al estilo de Tony Stark (Iron Man) y el cuartel general de Los Vengadores. Obras en ediciones limitadas adornarán las paredes de las habitaciones y suites."}
                      />
                    </Box>
                    <Box d="flex" mt="40px">
                      <Post
                        right={true}
                        categoria1={"DISNEYLAND PARÍS"}
                        categoria2={"HOTELES"}
                        titulo={"Hotel New York: Lo mejor para los apasionados de Marvel"}
                        descripcion={"La reforma del hotel esta en marcha desde comienzos de 2019 y creará un entorno que evoca un lujoso y contemporáneo establecimiento de Manhattan. Su diseño interior será elegante y cosmopolita, al estilo de Tony Stark (Iron Man) y el cuartel general de Los Vengadores. Obras en ediciones limitadas adornarán las paredes de las habitaciones y suites."}
                      />
                      <Post
                        categoria1={"DISNEYLAND PARÍS"}
                        categoria2={"HOTELES"}
                        titulo={"Hotel New York: Lo mejor para los apasionados de Marvel"}
                        descripcion={"La reforma del hotel esta en marcha desde comienzos de 2019 y creará un entorno que evoca un lujoso y contemporáneo establecimiento de Manhattan. Su diseño interior será elegante y cosmopolita, al estilo de Tony Stark (Iron Man) y el cuartel general de Los Vengadores. Obras en ediciones limitadas adornarán las paredes de las habitaciones y suites."}
                      />
                    </Box>
                  </Box>
                  <Box d="flex" mt="40px">
                    <Text fontSize="3xl">✨ &nbsp;</Text>
                    <Text fontSize="3xl" fontWeight="extrabold" color="#38414a" bgGradient="linear(to-r, #65b8ff, #9d7efd, #f87b90)" bgClip="text">Novedades</Text>
                  </Box>
                  <Text color="gray.500">Estos son los artículos más recientes que hemos publicado</Text>
                  <Box w={{base: "100vw", "3lg": "800px"}} mt="30px" bg="">
                    <Box d="flex">
                      <Post
                        right={true}
                        categoria1={"DISNEYLAND PARÍS"}
                        categoria2={"HOTELES"}
                        titulo={"Hotel New York: Lo mejor para los apasionados de Marvel"}
                        descripcion={"La reforma del hotel esta en marcha desde comienzos de 2019 y creará un entorno que evoca un lujoso y contemporáneo establecimiento de Manhattan. Su diseño interior será elegante y cosmopolita, al estilo de Tony Stark (Iron Man) y el cuartel general de Los Vengadores. Obras en ediciones limitadas adornarán las paredes de las habitaciones y suites."}
                      />
                      <Post
                        categoria1={"DISNEYLAND PARÍS"}
                        categoria2={"HOTELES"}
                        titulo={"Hotel New York: Lo mejor para los apasionados de Marvel"}
                        descripcion={"La reforma del hotel esta en marcha desde comienzos de 2019 y creará un entorno que evoca un lujoso y contemporáneo establecimiento de Manhattan. Su diseño interior será elegante y cosmopolita, al estilo de Tony Stark (Iron Man) y el cuartel general de Los Vengadores. Obras en ediciones limitadas adornarán las paredes de las habitaciones y suites."}
                      />
                    </Box>
                    <Box d="flex" mt="40px">
                      <Post
                        right={true}
                        categoria1={"DISNEYLAND PARÍS"}
                        categoria2={"HOTELES"}
                        titulo={"Hotel New York: Lo mejor para los apasionados de Marvel"}
                        descripcion={"La reforma del hotel esta en marcha desde comienzos de 2019 y creará un entorno que evoca un lujoso y contemporáneo establecimiento de Manhattan. Su diseño interior será elegante y cosmopolita, al estilo de Tony Stark (Iron Man) y el cuartel general de Los Vengadores. Obras en ediciones limitadas adornarán las paredes de las habitaciones y suites."}
                      />
                      <Post
                        categoria1={"DISNEYLAND PARÍS"}
                        categoria2={"HOTELES"}
                        titulo={"Hotel New York: Lo mejor para los apasionados de Marvel"}
                        descripcion={"La reforma del hotel esta en marcha desde comienzos de 2019 y creará un entorno que evoca un lujoso y contemporáneo establecimiento de Manhattan. Su diseño interior será elegante y cosmopolita, al estilo de Tony Stark (Iron Man) y el cuartel general de Los Vengadores. Obras en ediciones limitadas adornarán las paredes de las habitaciones y suites."}
                      />
                    </Box>
                  </Box>
                </Box>
              </WrapItem>
              <WrapItem>
                <Box w={{base: "100vw", "3lg": "320px"}} bg="transparent">
                  <Box d="flex">
                    <Text fontSize="3xl">🎥 &nbsp;</Text>
                    <Text fontSize="3xl" fontWeight="extrabold" color="#38414a" bgGradient="linear(to-r, #65b8ff, #9d7efd, #f87b90)" bgClip="text">Vídeos</Text>
                  </Box>
                  <Text color="gray.500">Hecha un vistazo a nuestros últimos vídeos</Text>
                  <Box mt="30px">
                    <Box w={{base: "320px", "3lg": "320px"}} h={{base: "180px", "3lg": "180px"}} borderRadius="13" boxShadow="md">
                      <Image
                          w={{base: "320px", md: "320px"}} 
                          h={{base: "180px", md: "180px"}}  
                          style={{position: 'absolute'}}
                          borderRadius="13"
                          src={foto1}
                          objectFit="cover"
                      />
                      <Box h="60px" w="60px" bg="white" position="absolute" ml="130px" mt="60px" borderRadius="30px" opacity="0.8">

                      </Box>
                    </Box>
                    <Text color="#143b5c" fontWeight="bold" mt="10px">LOS SECRETOS de DISNEYLAND PARÍS - Descubriendo MAIN STREET</Text>
                  </Box>
                  <Box mt="20px">
                    <Box w={{base: "320px", "3lg": "320px"}} h={{base: "180px", "3lg": "180px"}} borderRadius="13" boxShadow="md">
                      <Image
                          w={{base: "320px", md: "320px"}} 
                          h={{base: "180px", md: "180px"}}  
                          style={{position: 'absolute'}}
                          borderRadius="13"
                          src={foto1}
                          objectFit="cover"
                      />
                      <Box h="60px" w="60px" bg="white" position="absolute" ml="130px" mt="60px" borderRadius="30px" opacity="0.8">
                        
                      </Box>
                    </Box>
                    <Text color="#143b5c" fontWeight="bold" mt="10px">LOS SECRETOS de DISNEYLAND PARÍS - Descubriendo MAIN STREET</Text>
                  </Box>
                  <Box mt="20px">
                    <Box w={{base: "320px", "3lg": "320px"}} h={{base: "180px", "3lg": "180px"}} borderRadius="13" boxShadow="md">
                      <Image
                          w={{base: "320px", md: "320px"}} 
                          h={{base: "180px", md: "180px"}}  
                          style={{position: 'absolute'}}
                          borderRadius="13"
                          src={foto1}
                          objectFit="cover"
                      />
                      <Box h="60px" w="60px" bg="white" position="absolute" ml="130px" mt="60px" borderRadius="30px" opacity="0.8">
                        
                      </Box>
                    </Box>
                    <Text color="#143b5c" fontWeight="bold" mt="10px">LOS SECRETOS de DISNEYLAND PARÍS - Descubriendo MAIN STREET</Text>
                  </Box>
                  <Box d="flex" mt="40px">
                    <Text fontSize="3xl">📸 &nbsp;</Text>
                    <Text fontSize="3xl" fontWeight="extrabold" color="#38414a" bgGradient="linear(to-r, #65b8ff, #9d7efd, #f87b90)" bgClip="text">Instagram</Text>
                  </Box>
                  <Text color="gray.500">Pásate por nuestro perfil de Instagram</Text>
                </Box>
              </WrapItem>
            </Wrap>
          </Box>

          {/*<ContentHomeV1/>
          <PiePagina/>*/}
        </Container>
      </VStack>
      {/*<VStack bgGradient="linear(#231f34, #4b2aa1)">
        <Container maxW="container.xl" minH="100vh">
            <NavBar></NavBar>

          <Box d="flex" h="100vh" alignItems="center">
            <Box maxW="90vw">
              <Text fontSize="xl" fontWeight="extrabold" color="rgba(255,255,255,0.1)" style={{marginBottom: 16}}>¿Sabías que...</Text>
              <Text fontSize="xl" fontWeight="extrabold" color="white" style={{marginBottom: 50}}>El castillo de París tiene 16 pequeñas torres: el número es la edad que tenía Aurora justo antes de pinchar la rueca mágica que la dormiría.</Text>
              <Text
                bgGradient="linear(to-l, #7928CA,#FF0080)"
                bgClip="text"
                fontSize="4xl"
                fontWeight="extrabold"
              >
                @elreinodelamagia
              </Text>
            </Box>
          </Box>
        </Container>
      </VStack>*/}
      {/*<Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          <ColorModeSwitcher justifySelf="flex-end" />
          <VStack spacing={8}>
            <Logo h="40vmin" pointerEvents="none" />
            <Text>
              Edit <Code fontSize="xl">src/App.js</Code> and save to reload.
            </Text>
            <Link
              color="teal.500"
              href="https://chakra-ui.com"
              fontSize="2xl"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn Chakra
            </Link>
            <Text
              bgGradient="linear(to-l, #7928CA,#FF0080)"
              bgClip="text"
              fontSize="6xl"
              fontWeight="extrabold"
            >
              Bienvenidos al
            </Text>
          </VStack>
        </Grid>
      </Box>*/}
    </ChakraProvider>
  );
}

export default App;
