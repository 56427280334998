import * as React from "react";
import { Box, Image, Flex, Badge, Text, Container, Spacer, Button, IconButton } from "@chakra-ui/react";
import { BsList } from 'react-icons/bs';
import { BsChevronDown, BsFillCaretDownFill } from "react-icons/bs";
import { BiChevronDown } from "react-icons/bi";
import { FiChevronDown, FiArrowRight} from "react-icons/fi";

import logo from '../../images/logo.jpg';

import InstagramLogo from './SocialLogos/InstagramLogo'
import YoutubeLogo from './SocialLogos/YoutubeLogo'
//import AmazonLogo from '../../SocialLogos/AmazonLogo'

class DropdownMenu extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            hoverParques: this.props.firstOption,
            hoverAtracciones: false,
            hoverHoteles: false,
            hoverRestaurantes: false,
            hoverConsejos: false,
            hoverCuriosidades: false,
        }
    };

    render() {
        return (
            <Box borderRadius="14px" boxShadow="lg" padding={this.props.showDropdown ? "16px" : "0px"} bg="white" mt="18px" d="flex">
                <Box w={this.props.showDropdown ? "300px" : "0px"}>
                    {this.props.showDropdown ?
                        <Box>
                            <Box 
                                bg={this.state.hoverParques ? "rgba(216, 222, 232,0.3)" : "rgba(0, 0, 0, 0)"} 
                                _hover={{bg: "rgba(216, 222, 232,0.3)"}} 
                                onMouseEnter={() => this.setState({ hoverParques: true })} 
                                onMouseLeave={() => this.setState({ hoverParques: false })} 
                                borderRadius="10px" p="10px" d="flex" alignItems="center"
                            >
                                <Box d="flex" alignItems="center">
                                    <Text mr="4">🏰</Text>
                                    <Text color={"#143b5c"} fontWeight="bold">Parques</Text>
                                </Box>
                                <Spacer />
                                <Box mt="2px">
                                    <FiArrowRight color="#d8dee8"/>
                                </Box>
                            </Box>
                            <Box 
                                bg={this.state.hoverAtracciones ? "rgba(216, 222, 232,0.3)" : "rgba(0, 0, 0, 0)"} 
                                _hover={{bg: "rgba(216, 222, 232,0.3)"}} 
                                onMouseEnter={() => this.setState({ hoverAtracciones: true })} 
                                onMouseLeave={() => this.setState({ hoverAtracciones: false })} 
                                borderRadius="10px" p="10px" d="flex" alignItems="center"
                            >                            
                                <Box d="flex" alignItems="center">
                                    <Text mr="4">🎢</Text>
                                    <Text color="#143b5c" fontWeight="bold">Atracciones</Text>
                                </Box>
                                <Spacer />
                                <Box mt="2px">
                                    <FiArrowRight color="#d8dee8"/>
                                </Box>
                            </Box>
                            <Box 
                                bg={this.state.hoverHoteles ? "rgba(216, 222, 232,0.3)" : "rgba(0, 0, 0, 0)"} 
                                _hover={{bg: "rgba(216, 222, 232,0.3)"}} 
                                onMouseEnter={() => this.setState({ hoverHoteles: true })} 
                                onMouseLeave={() => this.setState({ hoverHoteles: false })} 
                                borderRadius="10px" p="10px" d="flex" alignItems="center"
                            >
                                <Box d="flex" alignItems="center">
                                    <Text mr="4">🏨</Text>
                                    <Text color="#143b5c" fontWeight="bold">Hoteles</Text>
                                </Box>
                                <Spacer />
                                <Box mt="2px">
                                    <FiArrowRight color="#d8dee8"/>
                                </Box>
                            </Box>
                            <Box 
                                bg={this.state.hoverRestaurantes ? "rgba(216, 222, 232,0.3)" : "rgba(0, 0, 0, 0)"} 
                                _hover={{bg: "rgba(216, 222, 232,0.3)"}} 
                                onMouseEnter={() => this.setState({ hoverRestaurantes: true })} 
                                onMouseLeave={() => this.setState({ hoverRestaurantes: false })} 
                                borderRadius="10px" p="10px" d="flex" alignItems="center"
                            >
                                <Box d="flex" alignItems="center">
                                    <Text mr="4">🍽</Text>
                                    <Text color="#143b5c" fontWeight="bold">Restaurantes</Text>
                                </Box>
                                <Spacer />
                                <Box mt="2px">
                                    <FiArrowRight color="#d8dee8"/>
                                </Box>
                            </Box>
                            <Box 
                                bg={this.state.hoverConsejos ? "rgba(216, 222, 232,0.3)" : "rgba(0, 0, 0, 0)"} 
                                _hover={{bg: "rgba(216, 222, 232,0.3)"}} 
                                onMouseEnter={() => this.setState({ hoverConsejos: true })} 
                                onMouseLeave={() => this.setState({ hoverConsejos: false })} 
                                borderRadius="10px" p="10px" d="flex" alignItems="center"
                            >
                                <Box d="flex" alignItems="center">
                                    <Text mr="4">💬</Text>
                                    <Text color="#143b5c" fontWeight="bold">Consejos</Text>
                                </Box>
                                <Spacer />
                                <Box mt="2px">
                                    <FiArrowRight color="#d8dee8"/>
                                </Box>
                            </Box>
                            <Box 
                                bg={this.state.hoverCuriosidades ? "rgba(216, 222, 232,0.3)" : "rgba(0, 0, 0, 0)"} 
                                _hover={{bg: "rgba(216, 222, 232,0.3)"}} 
                                onMouseEnter={() => this.setState({ hoverCuriosidades: true })} 
                                onMouseLeave={() => this.setState({ hoverCuriosidades: false })} 
                                borderRadius="10px" p="10px" d="flex" alignItems="center"
                            >
                                <Box d="flex" alignItems="center">
                                    <Text mr="4">💡</Text>
                                    <Text color="#143b5c" fontWeight="bold">Curiosidades</Text>
                                </Box>
                                <Spacer />
                                <Box mt="2px">
                                    <FiArrowRight color="#d8dee8"/>
                                </Box>
                            </Box>
                        </Box>
                    :
                        null
                    }
                </Box>
                <Box  w={this.props.showDropdown ? "400px" : "0px"}>
                    {this.props.showDropdown ?
                        this.state.hoverParques ?
                            <Text>Parques</Text>
                        : this.state.hoverAtracciones ? 
                            <Text>Atracciones</Text>
                        : this.state.hoverHoteles ? 
                            <Text>Hoteles</Text>
                        : this.state.hoverRestaurantes ?
                            <Text>Restaurantes</Text>
                        : this.state.hoverConsejos ?
                            <Text>Consejos</Text>
                        : this.state.hoverCuriosidades ?
                            <Text>Curiosidades</Text>
                        : null
                    :
                        null
                    }
                </Box>
            </Box>
        );
    }
}

export default DropdownMenu;