import * as React from "react";
import { Box, Image, Flex, Badge, Text, Container, Spacer, Button, IconButton } from "@chakra-ui/react";

import instagram_logo from '../../../images/instagram_logo.png'

export default function NavBar(props) {
    return (
        <Image
            h={{base: 50, md: 23}}
            w={{base: 50, md: 23}}
            src={instagram_logo}
            objectFit="cover"
        />
  );
}

