import * as React from "react";
import { Box, Image, Flex, Badge, Text, Container, Spacer, Button, IconButton } from "@chakra-ui/react";
import { BsList } from 'react-icons/bs';
import { BsChevronDown, BsFillCaretDownFill } from "react-icons/bs";
import { BiChevronDown } from "react-icons/bi";
import { FiChevronDown, FiArrowRight} from "react-icons/fi";

import logo from '../../images/logo.jpg';

import InstagramLogo from './SocialLogos/InstagramLogo'
import YoutubeLogo from './SocialLogos/YoutubeLogo'
import DropdownMenu from "./DropdownMenu";
//import AmazonLogo from '../../SocialLogos/AmazonLogo'

class NavBar extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            hoverDisneyland: false,
            firstOption: true,
        }
    };

    render() {
        return (
            <Box maxW="100%" overflow="hidden">
                <Box d="flex" h={{base: 78, md: 110}} alignItems="center" borderBottomWidth="1px">
                    <Box h={{base: 50, md: 61}} w={{base: 50, md: 61}} boxShadow="md" borderRadius={"300px"} backgroundColor={"rgba(216, 222, 232,0.3)"} style={{overflow: 'hidden'}} ml={{ base: 4, md: 12 }}>
                        <Image
                            minW={{base: 53, md: 63}}
                            h={{base: 53, md: 63}}
                            src={logo}
                            objectFit="cover"
                            borderRadius="full"
                            size="xs"
                            marginLeft="-1px"
                            marginTop="-1px"
                        />
                    </Box>
                    <Box ml={6}>
                        <Text fontSize={{base: "md", md: "lg"}} bgGradient="linear(to-r, #65b8ff, #9d7efd, #f87b90)" bgClip="text">Mireia y Albert</Text>
                        <Text fontSize={{base: "lg", md: "xl"}} fontWeight="extrabold" bgGradient="linear(to-r, #65b8ff, #9d7efd, #f87b90)" bgClip="text">El Reino de la Magia</Text>
                    </Box>
                    <Spacer/>
                    <Box d="flex" maxW={{base: 0, "2lg": 9999999}} mr={{base: 0, "2lg": 12}}>
                        <a href="#">
                            <Text fontWeight="extrabold" bgGradient="linear(to-r, #9d7efd, #f87b90)" bgClip="text" _hover={{bgGradient: "linear(to-r, #9d7efd, #f87b90)", bgClip: "text"}}>Inicio</Text>
                        </a>
                        <Box w="8"/>
                        <a href="#">
                            <Box 
                                onMouseEnter={() => this.setState({ hoverDisneyland: true, firstOption: false })} 
                                onMouseLeave={() => this.setState({ hoverDisneyland: false, firstOption: true })} 
                                h={this.state.hoverDisneyland ? undefined : "0px"} 
                                w={this.state.hoverDisneyland ? undefined : "0px"} 
                                position="absolute" mt="18px" zIndex="99999" ml="-300px"
                            >
                                <DropdownMenu showDropdown={this.state.hoverDisneyland} firstOption={this.state.firstOption}/>
                            </Box>
                            <Box d="flex" alignItems="center" onMouseOver={() => this.setState({ hoverDisneyland: true })} onMouseOut={() => this.setState({ hoverDisneyland: false })}>
                                <Text 
                                    bgGradient={this.state.hoverDisneyland ? "linear(to-r, #9d7efd, #f87b90)" : "linear(to-r, #bbc1c9, #bbc1c9)"} 
                                    bgClip="text" 
                                    _hover={{bgGradient: "linear(to-r, #9d7efd, #f87b90)", bgClip: "text"}}
                                >
                                    Disneyland París
                                </Text>
                                <Box ml="1.5" mt="0.5">
                                    {/* NO BORRAR (ES LA FORMA DE PONER UN ICONO CON GRADIENT)
                                    
                                    <svg width="0" height="0">
                                        <linearGradient id="blue-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
                                            <stop stopColor="#9d7efd" offset="0%" />
                                            <stop stopColor="#f87b90" offset="100%" />
                                        </linearGradient>
                                    </svg>
                                    <BsChevronDown fill="url(#blue-gradient)" size="10"/>*/}
                                    <FiChevronDown color={this.state.hoverDisneyland ? "#f87b90" : "#bbc1c9"} size="16"/>
                                </Box>
                            </Box>
                        </a>
                        <Box w="8"/>
                        <a href="#">
                            <Text color="#bbc1c9" _hover={{bgGradient: "linear(to-r, #9d7efd, #f87b90)", bgClip: "text"}}>Sobre nosotros</Text>
                        </a>
                        <Box w="8"/>
                        <a href="#">
                            <Text color="#bbc1c9" _hover={{bgGradient: "linear(to-r, #9d7efd, #f87b90)", bgClip: "text"}}>Contacto</Text>
                        </a>
                    </Box>
                    {/* OPCIONES NAVBAR A PARTIR DE MD */}
                    <Box d="flex" maxW={{base: 0, "2lg": 9999999}}>
                        {/*<a href="https://amzn.to/34rWIgE" target="_blank">
                            <Box h={{base: 0, md: 41}} w={{base: 0, md: 41}} borderRadius={"300px"} mr={{ base: 0, md: 4 }}>
                                <IconButton  icon={<AmazonLogo/>} h={{base: 0, md: 41}} w={{base: 0, md: 41}} isRound={true} bg="#d8dee8" _hover={{ bg: "#ff9900" }} />
                            </Box>
                        </a>*/}
                        <a href="https://www.youtube.com/channel/UCQLWGG87ijcYGfru_tFHaNA" target="_blank">
                            <Box h={{base: 0, md: 41}} w={{base: 0, md: 41}} borderRadius={"300px"} mr={{ base: 0, md: 4 }}>
                                <IconButton  icon={<YoutubeLogo/>} h={{base: 0, md: 41}} w={{base: 0, md: 41}} isRound={true} bg="#d8dee8" _hover={{ bg: "#ff0000" }} />
                            </Box>
                        </a>
                        <a href="https://www.instagram.com/elreinodelamagia" target="_blank">
                            <Box h={{base: 0, md: 41}} w={{base: 0, md: 41}} borderRadius={"300px"} mr={{ base: 4, md: 12 }}>
                                <IconButton icon={<InstagramLogo/>} h={{base: 0, md: 41}} w={{base: 0, md: 41}} isRound={true} bg="#d8dee8" _hover={{ bgGradient: "linear(to-r, #b532a5, #f9963b)" }} />
                            </Box>
                        </a>
                    </Box>
                    
                </Box>
            </Box>
        );
    }
}

export default NavBar;

